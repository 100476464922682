const TwitterIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21 6.728C20.3343 7.025 19.6237 7.223 18.8771 7.313C19.6417 6.854 20.2264 6.134 20.4963 5.27C19.7856 5.693 18.994 5.999 18.1484 6.17C17.4738 5.45 16.5202 5 15.4498 5C13.4078 5 11.7616 6.656 11.7616 8.69C11.7616 8.978 11.7976 9.257 11.8606 9.536C8.7931 9.383 6.07646 7.916 4.25037 5.675C3.94453 6.224 3.76462 6.854 3.76462 7.529C3.76462 8.807 4.41229 9.941 5.41079 10.607C4.7991 10.589 4.23238 10.418 3.72864 10.148C3.72864 10.166 3.72864 10.175 3.72864 10.193C3.72864 11.984 4.997 13.478 6.68816 13.811C6.38231 13.892 6.04948 13.937 5.71664 13.937C5.48276 13.937 5.24888 13.91 5.02399 13.874C5.49175 15.341 6.85907 16.412 8.46927 16.439C7.2099 17.429 5.61769 18.023 3.88156 18.023C3.58471 18.023 3.28786 18.005 3 17.969C4.63718 19.013 6.57121 19.625 8.65817 19.625C15.4498 19.625 19.1559 14 19.1559 9.122C19.1559 8.96 19.1559 8.807 19.1469 8.645C19.8756 8.123 20.5052 7.475 21 6.728Z"
			fill="currentColor"
		/>
	</svg>
)

export default TwitterIcon
